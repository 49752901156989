@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Droid Serif';
    src: local('DroidSerif-Bold.ttf'), url('./fonts/DroidSerif-Bold.ttf') format('truetype');
}

html {
    font-size: 16px;
}

body {
    font-family: 'Montserrat', sans-serif;
}

.ui-checkbox__wrapper>input:checked {
    background-color: aqua !important;
    color: white;
}